import moment from 'moment';

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
var $body = $('body');
(function () {
    var advancedQuery = {
        $form: $('.frmSearch'),
        magicSuggestObjects: [],

        init: function () {
            advancedQuery.control();
        },

        control: function () {
            this.focusFirstInput();
            this.removeFilters();
            this.highlightProceeding();
            this.check.submitButton();
            this.observeDateRangeChange();

            var $magicSuggestPre = $('.magic-suggest-pre');
            $magicSuggestPre.each(function () {
                UX.advancedQuery.doSuggestPreDefined($(this));
            });

            UX.advancedQuery.dateStart = null;
            UX.advancedQuery.dateEnd = null;
            UX.advancedQuery.$withinSection = $('.advanced-search__search-in');
            UX.advancedQuery.withinSectionEditableVal = '';
            UX.advancedQuery.$advancedForm = $('form.advanced-search');
            UX.advancedQuery.$editableQueryField = $('#editableQuery');
            UX.advancedQuery.$editableQueryFieldVal = null;
            UX.advancedQuery.$fullQueryField = $('#fullQuery');
            UX.advancedQuery.$disabled = null;
            UX.advancedQuery.$refinementWrapper = $('.advanced-search--refinement');

            $(document).ready(function () {
                if ($('.daterange--double').length) {
                    UX.advancedQuery.dateStart = $('.dr-dates .dr-date-start').html();
                    UX.advancedQuery.dateEnd = $('.dr-dates .dr-date-end').html();
                }
            });

            $body.on('click', '#advanced-search-clear', function (e) {
                e.preventDefault();
                window.location.href = window.location.origin + window.location.pathname;
            });

            $('.advanced-search__search-in, .advanced-search--query').on('focusin', 'input, textarea', function () {
                var $this = $(this),
                    editableQueryVal = UX.advancedQuery.$editableQueryField.val(),
                    fullQueryVal = UX.advancedQuery.$fullQueryField.val(),
                    isAllField = editableQueryVal.includes(':(');

                if (!isAllField) {
                    let reg;
                    if (editableQueryVal !== '') {
                        reg = new RegExp(escapeRegExp(editableQueryVal));
                        editableQueryVal = 'AllField:(' + editableQueryVal + ')';
                        fullQueryVal = fullQueryVal.replace(reg, 'AllField:(' + fullQueryVal.match(reg) + ')');
                    } else {
                        reg = new RegExp(escapeRegExp('"query": {  }'), 'g');
                        editableQueryVal = 'AllField:(' + editableQueryVal + ')';
                        fullQueryVal = fullQueryVal.replace(reg, '"query": { AllField:() }');
                    }

                    UX.advancedQuery.$editableQueryField.val(editableQueryVal);
                }

                UX.advancedQuery.$editableQueryFieldVal = editableQueryVal;
                UX.advancedQuery.$fullQueryField.val(fullQueryVal);
                UX.advancedQuery.withinSectionEditableVal = $this.val();
            });

            $('.exportQuery__name').on('input', 'input', function () {
                var $downloadLink = $('.exportQuery__download');
                var url = $downloadLink.attr('href');
                var reExp = /fileName=[^]+/g;
                $downloadLink.attr('href', url.replace(reExp, $('.exportQuery__name input').serialize()));
            });

            $body.on('keyup input', '.advanced-search__search-in__term input', function () {
                advancedQuery.check.submitButton();
            });

            UX.advancedQuery.$withinSection.on('input', 'input', function () {
                if ($('#editableQuery').length && UX.advancedQuery.$editableQueryField.data('edit') === true) {
                    let $this = $(this),
                        itemVal = $this.val(),
                        itemName = $this.closest('.advanced-search__search-in__term').find('select').val(),
                        editableQueryVal = UX.advancedQuery.$editableQueryField.val(),
                        fullQueryVal = UX.advancedQuery.$fullQueryField.val(),
                        filterNameWithVal = editableQueryVal.includes(
                            itemName + ':(' + UX.advancedQuery.withinSectionEditableVal + ')'
                        ),
                        editVal = itemName + ':(' + itemVal + ')';

                    let reg = new RegExp(
                        escapeRegExp(itemName + ':(' + UX.advancedQuery.withinSectionEditableVal + ')'),
                        'g'
                    );

                    let editQuery = editableQueryVal.replace(reg, editVal),
                        fullQuery = fullQueryVal.replace(reg, editVal);

                    if (filterNameWithVal) {
                        UX.advancedQuery.$editableQueryField.val(editQuery);
                        UX.advancedQuery.$editableQueryFieldVal = editQuery;
                        UX.advancedQuery.$fullQueryField.val(fullQuery);
                    } else {
                        reg = new RegExp(escapeRegExp(editQuery), 'g');

                        fullQuery = fullQueryVal.replace(reg, fullQueryVal.match(reg) + ' AND ' + editVal);

                        UX.advancedQuery.$editableQueryFieldVal = editableQueryVal + ' AND ' + editVal;
                        UX.advancedQuery.$editableQueryField.val(UX.advancedQuery.$editableQueryFieldVal);
                        UX.advancedQuery.$fullQueryField.val(fullQuery);
                    }

                    UX.advancedQuery.withinSectionEditableVal = itemVal;
                }
            });

            UX.advancedQuery.$advancedForm.on('change', 'input, select', function () {
                UX.advancedQuery.editQuery(true, $(this));
            });

            $('.advanced-search__autocomplete').on('click', function () {
                $('.autoComplete_wrapper .autoComplete--tags').on('click', function () {
                    UX.advancedQuery.editQueryAjaxCall();
                });
            });

            UX.advancedQuery.$advancedForm.on('click', '#editableQuery--reset', function (e) {
                e.preventDefault();
                UX.advancedQuery.editQuery(false, $(this));
            });

            UX.advancedQuery.$advancedForm.on('click', '#exportQuery__crtl', function (e) {
                e.preventDefault();
                e.stopPropagation();
                UX.advancedQuery.exportQuery($(this));
            });

            UX.advancedQuery.$editableQueryField.on('input', function () {
                UX.advancedQuery.$editableQueryField.data('edit', 'false');

                let editableQueryVal = UX.advancedQuery.$editableQueryField.val(),
                    fullQueryVal = UX.advancedQuery.$fullQueryField.val(),
                    reg = new RegExp(escapeRegExp(UX.advancedQuery.$editableQueryFieldVal), 'g');

                let fullQuery = fullQueryVal.replace(reg, editableQueryVal);
                UX.advancedQuery.$fullQueryField.val(fullQuery);
                UX.advancedQuery.$editableQueryFieldVal = editableQueryVal;
            });

            $('form.advanced-search, .advanced-search--event').on('submit', function (e) {
                e.preventDefault();
                UX.advancedQuery.emptyField($(this));
                this.submit();
                let ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        UX.advancedQuery.$editableQueryField.attr('name', '');
                        return false;
                    }
                }
            });
            document.querySelector('form.advanced-search')?.addEventListener('submit', function (e) {
                const advancedForm = document.querySelector('.advanced-search');
                const advancedFormFeilds = advancedForm.querySelectorAll('input, select');
                advancedFormFeilds.forEach(field => {
                    field.removeAttribute('disabled');
                });
                UX.advancedQuery.check.submitButton();
            });

            $('.ms-sel-ctn input').attr('autocomplete', 'off');
        },

        observeDateRangeChange: function () {
            // observe date range changes
            const targetNode = document.querySelector('.daterange .dr-date');
            if (targetNode) {
                const config = {childList: true, subtree: true};
                const observer = new MutationObserver(function (mutationsList) {
                    for (let mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            const startDate = document.querySelector('.dr-dates .dr-date-start').innerHTML;
                            const endDate = document.querySelector('.dr-dates .dr-date-end').innerHTML;
                            UX.advancedQuery.setEventsFields(startDate, endDate);

                            if (
                                (UX.advancedQuery.dateStart !== startDate && startDate !== '') ||
                                (UX.advancedQuery.dateEnd !== endDate && endDate !== '')
                            ) {
                                UX.advancedQuery.check.submitButton();
                            }
                        }
                    }
                });
                observer.observe(targetNode, config);
            }
        },

        focusFirstInput: function () {
            advancedQuery.$form.find('input[type=text]:first').focus();
        },

        removeFilters: function () {
            $body.on('click', '.remove-filter, .remove-filter--all', function (e) {
                var $this = $(this);
                e.preventDefault();

                if ($this.is('.remove-filter--all')) {
                    UX.advancedQuery.$refinementWrapper.remove();
                } else {
                    $this.closest('li').remove();

                    if (UX.advancedQuery.$refinementWrapper.find('li').length === 1) {
                        UX.advancedQuery.$refinementWrapper.remove();
                    }
                }
                UX.advancedQuery.editQuery(true, $(this));
            });
        },
        highlightProceeding: function () {
            var $proceedingSearch = $('.proceeding-search-container'),
                $inputVal,
                $inputValWords;
            if ($proceedingSearch.length) {
                $inputVal = $proceedingSearch.find('.quick-search__input').val();
                if ($inputVal.length && $('.proceedings-browse--alphabetical').length) {
                    $inputValWords = $inputVal.replace(/\"/gi, '').split(' ');
                    $inputValWords.unshift($inputVal);

                    $('.proceedings-browse--alphabetical').highlight($inputValWords);
                    setTimeout(function () {
                        $('.proceedings-browse--alphabetical').highlight($inputValWords);
                    }, 500);
                }
            }
        },
        emptyField: function ($form) {
            $form.find('.autoComplete_wrapper input:not(:hidden), .removeName').attr('name', '');
            UX.advancedQuery.setOperators();
            if ($('.advanced-search').length) {
                UX.advancedQuery.$disabled = $form
                    .find(':input')
                    .filter(function () {
                        return !this.value;
                    })
                    .attr('disabled', 'disabled');
            }

            if ($form.is('.advanced-search') && $('#editableQuery').length) {
                UX.advancedQuery.$withinSection.find('select, input').attr('disabled', 'disabled');
                UX.advancedQuery.$editableQueryField.attr('name', 'AllField');
            }

            return true; // make sure that the form is still submitted
        },
        doSuggestPreDefined: function ($elem) {
            var data,
                name = $elem.attr('name'),
                id = $elem.attr('id'),
                values = $elem.attr('data-values') ? JSON.parse($elem.attr('data-values')) : [];

            if (typeof $elem.attr('data-list') !== 'undefined') {
                data = JSON.parse($elem.attr('data-list'));

                var $ms = $elem.magicSuggest({/*eslint-disable-line*/
                    data: data,
                    allowFreeEntries: false,
                    valueField: 'code',
                    cls: 'search-term',
                    inputCfg: {id: id + '-1', name: name},
                });

                UX.advancedQuery.magicSuggestObjects.push($ms);

                $($ms).on('selectionchange', function () {
                    UX.advancedQuery.check.submitButton();
                });
            }

            if (values.length) {
                $ms.setValue(values);
            }
        },
        setEventsFields: function (startDate, endDate) {
            var dateStartDate = moment(startDate).format('YYYYMMDD');
            var dateEndDate = moment(endDate).format('YYYYMMDD');
            var finalDateValue = '[' + dateStartDate + ' TO ' + dateEndDate + ']';
            $('#event-date-input').val(finalDateValue);
        },
        editQueryAjaxCall: function () {
            var editQueryLink = UX.advancedQuery.$advancedForm.attr('data-reseturl');
            UX.advancedQuery.$advancedForm
                .find('.autoComplete_wrapper input:not(:hidden), .removeName')
                .attr('name', '');
            UX.advancedQuery.setOperators();

            $.ajax({
                // url:'/specs/products/acm/widgets/advanced-search/templates/data/ajax.json', // for local testing
                url: editQueryLink,
                data: UX.advancedQuery.$advancedForm.serializeArray(),
                async: true,
                success: function (response) {
                    response = JSON.parse(response); // comment this to test locally
                    UX.advancedQuery.$editableQueryField.val(response.editableQuery);

                    let filtersQuery = '';
                    response.appliedFilters.forEach(function (item, index) {
                        filtersQuery += item;
                        if (index !== response.appliedFilters.length - 1) filtersQuery += ', ';
                    });

                    UX.advancedQuery.$fullQueryField.val(
                        '"query": { ' + response.editableQuery + ' } \n' + '"filter": { ' + filtersQuery + ' }'
                    );
                },
            });

            UX.advancedQuery.resetField();
        },
        editQuery: function (editable, $this) {
            var canEdit = editable
                ? (UX.advancedQuery.$editableQueryField.length &&
                      UX.advancedQuery.$editableQueryField.data('edit') === true) ||
                  UX.advancedQuery.$fullQueryField.length
                : UX.advancedQuery.$editableQueryField.length || UX.advancedQuery.$fullQueryField.length;

            if (canEdit) {
                if (!$this.closest('.autoComplete_wrapper').length) {
                    UX.advancedQuery.editQueryAjaxCall();
                }
            }

            if (!editable) {
                UX.advancedQuery.$editableQueryField.data('edit', 'true');
            }
        },
        setOperators: function () {
            $('.advanced-search--filters-fields .advanced-search__search-in__term').each(function () {
                var $this = $(this),
                    $elem = $this.find('[id*=operator]'),
                    opValue = $elem.val(),
                    $input = $this.find('input:not(.removeName)'),
                    fieldName = $this.find('[id*=searchArea]').val();

                $elem.attr('disabled', 'disabled');
                $this.find('[id*=searchArea]').attr('disabled', 'disabled');

                $input.each(function () {
                    $(this).attr('name', fieldName + opValue);
                });
            });
        },
        resetField: function () {
            UX.advancedQuery.$withinSection.find('select, input').removeAttr('disabled');
            UX.advancedQuery.$disabled?.removeAttr('disabled');
            UX.advancedQuery.$refinementWrapper.find('input').removeAttr('disabled');
            $('[id*=operator], [id*=searchArea]').removeAttr('disabled');
        },
        exportQuery: function ($this) {
            $this.addClass('load');
            let $exportSyntax = $('#exportQuery'),
                exportQueryLink = $this.data('url'),
                exportQueryId = $this.attr('data-queryId');

            UX.advancedQuery.emptyField(UX.advancedQuery.$advancedForm);

            if (exportQueryId !== undefined) {
                exportQueryLink += '?queryId=' + exportQueryId + '&' + UX.advancedQuery.$advancedForm.serialize();
            } else {
                exportQueryLink += '?' + UX.advancedQuery.$advancedForm.serialize();
            }

            $.ajax({
                url: exportQueryLink,
                async: true,
                success: function (response) {
                    var fileName = 'queryExp';
                    if (response.queryName !== '') {
                        $exportSyntax.find('.exportQuery__name').html(response.queryName);
                        fileName = response.queryName;
                    } else {
                        $exportSyntax
                            .find('.exportQuery__name')
                            .html('<input type="text" name="fileName" placeholder="Name">');
                    }
                    $exportSyntax.find('.exportQuery__date').html(response.searchRunDate);
                    $exportSyntax.find('.exportQuery__count').html(response.resultCount);
                    $exportSyntax.find('.exportQuery__syntax').html(response.fullQuerySyntax);
                    $exportSyntax
                        .find('.exportQuery__download')
                        .attr('href', exportQueryLink + '&downloadCsv=true&fileName=' + encodeURIComponent(fileName));
                    UX.advancedQuery.resetField();
                    $('.advanced-search__timeframe__item select').each(function () {
                        $(this).attr('disabled', 'disabled');
                    });
                    UX.advancedQuery.$editableQueryField.removeAttr('name');

                    $('#exportQuery').modal('toggle');
                    $this.removeClass('load');
                },
                error: function () {
                    UX.advancedQuery.resetField();
                },
            });
        },
        hideTriggerOption: function ($elem) {
            var hideTrigger = true;
            if ($elem.hasClass('showDefaultData')) {
                hideTrigger = false;
            }
            return hideTrigger;
        },
        check: {
            emptyInputs: function (form) {
                var textInputs = $(form).find('input[type=search]');
                if (textInputs.length === 0) {
                    textInputs = $(form).find('input[type=text]');
                }

                textInputs = $(textInputs).add($(form).find('.checkVal'));

                const $emptyFields = $(textInputs).filter(function () {
                    return this.value === '';
                });
                return $emptyFields.length === textInputs.length;
            },
            submitButton: function () {
                const isEventsForm = UX.advancedQuery.$form.hasClass('advanced-search--event');
                if (
                    !isEventsForm &&
                    UX.advancedQuery.check.emptyInputs(UX.advancedQuery.$form) &&
                    UX.advancedQuery.check.emptySuggestions()
                ) {
                    $(UX.advancedQuery.$form)
                        .find('button[type=submit], #advanced-search-clear, .advanced-search-clear')
                        .attr('disabled', true);
                } else {
                    $(UX.advancedQuery.$form)
                        .find('button[type=submit], #advanced-search-clear, .advanced-search-clear')
                        .removeAttr('disabled', true);
                }
            },
            emptySuggestions() {
                const suggestions = advancedQuery.magicSuggestObjects;
                if (!suggestions.length) {
                    return true;
                }
                return suggestions.filter(suggestion => suggestion.getValue().length > 0).length === 0;
            },
        },
        on: {},
    };
    UX.advancedQuery = advancedQuery;
})();
